import React, { useCallback, useState } from 'react';
import { ButtonGroup } from '@atlaskit/button';
import CopyIcon from '@atlaskit/icon/core/migration/copy';
import { Box, xcss } from '@atlaskit/primitives';
import { type UIAnalyticsEvent, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { Action } from './action';

type Props = {
	source: string;
	actionSubjectId?: string;
};

export const TOOLTIP_MESSAGE = {
	PROMPT: 'Copy',
	SUCCESS: 'Copied!',
	FAILURE: 'Copy to clipboard failed',
};

/**
 * A copy action displays an icon to copy the contents of the code block.
 */
export const CopyAction = ({ source, actionSubjectId }: Props) => {
	const [tooltipMessage, setTooltipMessage] = useState(TOOLTIP_MESSAGE.PROMPT);

	const handleSuccess = useCallback(() => {
		setTooltipMessage(TOOLTIP_MESSAGE.SUCCESS);
	}, [setTooltipMessage]);

	const handleError = useCallback(() => {
		setTooltipMessage(TOOLTIP_MESSAGE.FAILURE);
	}, [setTooltipMessage]);

	const writeToClipboard = useCallback(
		(_: unknown, analyticsEvent: UIAnalyticsEvent) => {
			actionSubjectId && fireUIAnalytics(analyticsEvent, 'clicked button', actionSubjectId);

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			navigator.clipboard.writeText(source).then(handleSuccess, handleError);
		},
		[source, handleSuccess, handleError, actionSubjectId],
	);

	const resetMessage = useCallback(() => {
		setTooltipMessage(TOOLTIP_MESSAGE.PROMPT);
	}, [setTooltipMessage]);

	function shouldRender() {
		if (typeof document !== 'undefined') {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			return Boolean(navigator?.clipboard?.writeText);
		}
	}

	return shouldRender() ? (
		<Action
			icon={CopyIcon}
			label={tooltipMessage}
			onClick={writeToClipboard}
			onMouseOver={resetMessage}
			onFocus={resetMessage}
			aria-live="assertive"
			aria-atomic="true"
			name="Copy"
		/>
	) : null;
};

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

/**
 * An action container sits at the top right of a code block container and displays various actions for the user.
 */
export const ActionContainer = ({ children }: { children: React.ReactNode }) => (
	/**
	 * This element uses an `onMouseDown` to block event propagation
	 * to its parent but is not itself interactive.
	 */
	<Box xcss={absolutePositionWrapperStyles} onMouseDown={stopPropagation}>
		<ButtonGroup label="actions">{children}</ButtonGroup>
	</Box>
);

const absolutePositionWrapperStyles = xcss({
	position: 'absolute',
	top: 'space.0',
	right: 'space.0',
	padding: 'space.050',
	zIndex: 'flag',
});
