import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { useRouterActions } from '@atlassian/react-resource-router';
import imageUrl from '../../assets/not-found.svg';
import { ErrorPage } from '../../common/ui';
import messages from './messages';

const NotFoundErrorPage = () => {
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();
	const onButtonClick = useCallback(() => push('/jira'), [push]);

	return (
		<UFOSegment name="not-found-error-page">
			<ErrorPage
				header={formatMessage(messages.header)}
				description={formatMessage(messages.description, { br: '{br}' })}
				buttonMessage={formatMessage(messages.primaryButton)}
				imageUrl={imageUrl}
				errorCode="404"
				onButtonClick={onButtonClick}
				error={null}
			/>
		</UFOSegment>
	);
};

export default NotFoundErrorPage;
