import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Heading, { type HeadingProps } from '@atlaskit/heading';
import Link from '@atlaskit/link';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { PAGE_LAYOUT_OFFSET_TOP } from '@atlassian/jira-common-constants/src/page-layout';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { SpaStatePageErrorReady } from '@atlassian/jira-spa-state-controller/src/components/main.tsx';
import { ErrorDetails } from './error-details';
import messages from './messages';

type Props = {
	header: string;
	description: ReactNode;
	buttonMessage: string;
	imageUrl: string;
	isFullPage?: boolean;
	linkMessage?: string;
	linkHref?: string;
	errorCode?: string;
	error: Error | null | undefined;
	onButtonClick: (e: React.MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
};
/**
 * Provides a user-friendly interface for displaying errors, supporting customization through various props. This component showcases an error message, a detailed description, and optionally, an image to visually represent the error state. Interactive elements, such as a button and a hyperlink, are rendered based on the provided conditions, allowing users to take action or navigate from the error page.
 */
export const ErrorPage = ({
	header,
	description,
	buttonMessage,
	imageUrl,
	isFullPage = true,
	linkMessage,
	linkHref,
	errorCode,
	onButtonClick,
	error,
}: Props) => {
	const { formatMessage } = useIntl();

	function formatDescription(input: ReactNode): ReactNode {
		if (typeof input !== 'string') {
			return <Text data-testid="error-pages.common.ui.error-description-0">{input}</Text>;
		}

		return input.split('{br}').map((p, i) => (
			<Text key={i} data-testid={`error-pages.common.ui.error-description-${i}`}>
				{p}
			</Text>
		));
	}

	const errorPageContent = (
		<ContentContainer>
			{/* eslint-disable-next-line jira/react/no-empty-divs */}
			<div>
				{/* @ts-expect-error TS2322 - Type '"p"' is not assignable to type '"div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | undefined'. */}
				<Heading size="large" as="p" testId="error-pages.common.ui.error-heading">
					{header}
				</Heading>
				{formatDescription(description)}
				{Boolean(linkMessage) && (
					<Link
						href={linkHref || 'https://status.atlassian.com/'}
						testId="error-pages.common.ui.error-link"
					>
						{linkMessage}
					</Link>
				)}
				{Boolean(errorCode) && (
					<ErrorCode>
						{formatMessage(messages.errorCode, {
							errorCode,
						})}
					</ErrorCode>
				)}
				{error && <ErrorDetails error={error} />}
				<Box xcss={buttonWrapperStyles}>
					<Button
						appearance="primary"
						onClick={onButtonClick}
						testId="error-pages.common.ui.error-button"
					>
						{buttonMessage}
					</Button>
				</Box>
			</div>
			<Image src={imageUrl} alt="" />
		</ContentContainer>
	);

	return isFullPage ? (
		<WindowContainerFullPageHeight>
			{errorPageContent}
			<SpaStatePageErrorReady />
		</WindowContainerFullPageHeight>
	) : (
		<WindowContainer>
			{errorPageContent}
			<SpaStatePageErrorReady />
		</WindowContainer>
	);
};

const ErrorCode = ({ children }: Pick<HeadingProps, 'children'>) => (
	<ErrorCodeWrapper>
		{/* @ts-expect-error T2322 - Type '"p"' is not assignable to type '"div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | undefined' */}
		<Heading level="h200" as="p" testId="error-pages.common.ui.error-code">
			{children}
		</Heading>
	</ErrorCodeWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WindowContainerFullPageHeight = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `calc(100vh - ${PAGE_LAYOUT_OFFSET_TOP})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const WindowContainer = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${(gridSize / 2) * 169}px`,
	maxWidth: '90%',
	alignItems: 'center',
});

const imageHeight = (gridSize / 2) * 45;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${imageHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${imageHeight}px`,
	marginLeft: token('space.400', '32px'),
	display: 'block',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Text = styled.p({
	paddingTop: token('space.200', '16px'),
	margin: 0,
});
const buttonWrapperStyles = xcss({
	paddingTop: 'space.400',
});
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorCodeWrapper = styled.div({
	paddingTop: token('space.200', '16px'),
	margin: 0,
});
