import React, { useMemo } from 'react';
import { Box, xcss } from '@atlaskit/primitives';
import { ErrorHashAndTraceId } from '@atlassian/jira-errors-handling/src/ui/error-hash-and-trace-id';
import { ErrorHash } from '@atlassian/jira-errors-handling/src/ui/index.tsx';
import { getActiveTrace } from '@atlassian/react-ufo/experience-trace-id-context';

interface Props {
	error?: Error;
}

export function ErrorDetails({ error }: Props) {
	// We need to memoize the traceId because once we display the trace ID in the UI, it should not change.
	const traceId = useMemo(() => {
		const traceContext = getActiveTrace();

		return traceContext?.traceId;
	}, []);

	if (error && traceId) {
		return (
			<Box xcss={styles}>
				<ErrorHashAndTraceId error={error} traceId={traceId} />
			</Box>
		);
	}

	if (error) {
		return (
			<Box xcss={styles}>
				<ErrorHash error={error} />
			</Box>
		);
	}
}
const styles = xcss({
	marginTop: 'space.100',
});
